<!--
 * @Descripttion: 验证码输入框
 * @FilePath: /azg/src/views/ditui/register/components/CodeField/index.vue
 * @Author: 张兴业
 * @Date: 2020-10-09 19:05:19
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-04-13 15:42:05
-->
<template>
  <div class="verify_code">
    <img
      slot="label"
      src="@/assets/img/ditui/lock.png"
      alt=""
      class="icon left"
    />
    <van-field
      v-model="verifyCode"
      name="verifyCode"
      placeholder="请输入验证码"
      label-width="52px"
      :class="verifyCode ? 'verify_code_field' : 'verify_code_field_un'"
      maxlength="6"
      type="number"
      @input="verifyCodeChange"
    >
      <template #button>
        <span
          v-if="!showTime"
          :class="phone ? 'sender_code' : 'sender_code_un'"
          @click="senderCode"
        >
          {{ senderText }}
        </span>
        <van-count-down
          v-if="showTime"
          :time="time"
          format="ss秒后重发"
          @finish="finish"
        />
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  props: ["value", "phone"],
  data() {
    return {
      verifyCode: this.value,
      showTime: false,
      senderText: "发送验证码",
      time: 60000
    };
  },
  methods: {
    verifyCodeChange(value) {
      this.$emit("input", value);
    },
    senderCode() {
      this.$emit("senderCode", () => {
        this.showTime = true;
      });
    },
    finish() {
      this.senderText = "重新发送";
      this.showTime = false;
    }
  }
};
</script>

<style lang="less" scoped>
.verify_code {
  display: flex;
  flex-direction: row;
  border-radius: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  .icon {
    width: 24px;
    height: 24px;
    margin: auto 12px;
  }

  .verify_code_field {
    font-size: 14px;
    font-weight: 500;
    border-radius: 13px;
    padding: 11px 12px 10px 0;
  }

  .verify_code_field_un {
    font-size: 14px;
    font-weight: 500;
    border-radius: 13px;
    padding: 11px 12px 10px 0;
  }

  ::v-deep .van-field__label {
    color: black;
    font-weight: 600;
    font-size: 12px;
  }

  .sender_code {
    background: white;
    border-color: white;
    color: black;
    font-size: 12px;
    text-decoration: underline;
    font-weight: normal;
  }

  .sender_code_un {
    background: white;
    border-color: white;
    color: black;
    opacity: 0.2;
    font-size: 12px;
    text-decoration: underline;
    font-weight: normal;
  }

  ::v-deep .van-count-down {
    font-size: 12px;
    font-weight: 400;
    color: black;
  }
}
</style>
